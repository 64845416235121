<template>
<DetailItemEditCard item-name="klanten" :promises.sync="detailItemEditCardPromises" title="Nieuwe klant aanmaken"
    :detailViewRouteLocation="{name: RouteNames.CLIENT_OVERVIEW}" confirmationMessage="Klant werdt succesvol aangemaakt" update-store-action="clientsModule/createClient"
     :update-store-action-payload="newClient" :success-callback="clientCreatedSuccessCallback">
      <template v-slot:form-fields>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="newClient.clientName" persistent-placeholder label="Klant naam" type="text" :rules="clientNameRules" required/>
            </v-col>
            <v-col cols="12" md="6">
              <DatePicker v-model="newClient.startDate" label="Start datum" required/>
            </v-col>
            <v-col cols="12" md="6">
              <DatePicker v-model="newClient.endDate" label="Eind datum" :min="newClient.startDate ? new Date(newClient.startDate) : null" :default-now="false"/>
            </v-col>
          </v-row>
      </template>
    </DetailItemEditCard>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue";
import DatePicker from "@/components/shared/DatePicker.vue";
import RouteNames from "@/router/RouteNames";
export default {
    name: "ClientCreate",
    components: {
        DetailItemEditCard,
        DatePicker,
    },
    data() {
        return {
          RouteNames,
          newClient: {},
          detailItemEditCardPromises: [],
          clientNameRules: [
            v => !!v || 'Klant naam moet ingevuld worden',
          ],
        }
    },
    methods: {
      clientCreatedSuccessCallback(clientId) {
        this.$router.push({name: RouteNames.CLIENT_DETAIL, params: {clientId: clientId}})
      }
    }
}
</script>